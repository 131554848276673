/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/*const app = new Vue({
    el: '#app',
});
*/

require('../../node_modules/bootstrap-select/dist/js/bootstrap-select');
require('select2');

require('../../node_modules/bootstrap4-toggle/js/bootstrap4-toggle.min.js');
require('../../node_modules/bootstrap-filestyle2/src/bootstrap-filestyle.min.js');
require('inputmask/dist/jquery.inputmask.min.js');


require("../KNET_resources/js/genelAjax");
require("../KNET_resources/js/tiklayincaKapat");
require("../KNET_resources/js/popover.js");
require("../KNET_resources/js/selectYukleyici.js");
require("../KNET_resources/js/animasyon.js");
require("../KNET_resources/js/tarihSaat.js");
require("../KNET_resources/js/dosyaSecici.js");
require("../KNET_resources/js/geriSayimAnimasyonu.js");



//import { saatFark } from '../KNET_resources/js/tarihSaat.js';