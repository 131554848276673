$(document).ready(function(){


    if ( document.getElementById('geriSayimAnimasyonu') != null ) {

        var e = ms2s( (new Date( $("#son_kullanim").val() )).getTime() );
        var t = $("#son_kullanim").data("sure") * 60;
        var counter = document.getElementById('geriSayimAnimasyonu').getContext('2d');
        //var no = 10;
        var pointToFill = 4.72;
        var cw = counter.canvas.width;
        var ch = counter.canvas.height;
        var diff;
        var fill;

        var x , y , radi, tx,ty, font, l, redirect;

        function ms2s ( ms) {
        	return Math.floor( ms / 1000);
        }

        function fillCounter() {

        	var n = ms2s( Date.now() );
        	no = e - n;

            if ( no <= 0 )
                window.location.href=window.redirect;

            diff = ((no / t) * Math.PI * 2 * t);
            counter.clearRect(0, 0, cw, ch);
            counter.lineWidth = window.l;
            counter.fillStyle = '#000';

            var min= Math.floor(no / 60);
            if (min < 2)
                counter.strokeStyle = '#FF0000';
            else
                counter.strokeStyle = '#F5E0A9';

            counter.textAlign = 'center';
            counter.font = window.font;


            var sec = no - ( 60 * min);
            if (sec < 10) sec = "0" + sec;
            if (min < 10) min = "0" + min;
            counter.fillText(min + ":" + sec, window.tx, window.ty);
            counter.beginPath();

            counter.arc(window.x, window.y, window.radi, pointToFill, diff / t + pointToFill);
            counter.stroke();

            if (no == 0) {
                clearTimeout(fill);
            }
            //no--;
        }

        window.geriSayimAnimasyonuBaslat = function (x , y , radi, tx,ty, font, l,redirect) {
            window.x = x;
            window.y = y;
            window.radi = radi;
            window.tx = tx;
            window.ty = ty;
            window.font = font;
            window.l = l;
            window.redirect = redirect;

            fill = setInterval(fillCounter, 1000);
        }
    }
});
