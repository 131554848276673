$(document).ready(function(){

    $('.pencereAcKapa').on('click', pencereAcKapa );       // hangi pencere olduğunu data attribute'den alırık
    $(document).keydown(function (e) { 
                            if(e.which == 27) {
                                $('#filtreDugme').trigger('click'); 
                                if ( (birinciTextAlani = $("#filtreCubugu input[type=textbox]").first()) != null)
                                    birinciTextAlani.focus();}
    });

    $(".filtreCubuguIslem").on("click", islemYap);
});


function pencereAcKapa (eventObj) {
    var pencereAdi =  $(this).data("pencere");
    var pencere = $('#' + pencereAdi);
    var dugme_id = pencere.data("dugme");
    var genislik = pencere.data("genislik");

    var filtreCubuguAnimasyonu = {  bitis       : {width: 0},
                                    baslangic   : {width: genislik } };

    if( pencereAdi == "filtreCubugu")
        var animasyon = filtreCubuguAnimasyonu;


    if ( pencere.data("acik") )
        pencere.data("acik", false).animate(animasyon.bitis, "fast", function () { $(this).hide(); $('#'+dugme_id).show(); } );
    else{
        $('#'+dugme_id).hide();
        pencere.data("acik", true).show().animate(animasyon.baslangic,"fast"); 
    }


}


function islemYap () {
    $("#islem").val( $(this).data("islem") );
    $("#filtreCubuguForm").submit();
}
