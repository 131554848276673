
window.tarihYMD2DMYCevir = function( tarih, ayrac="/"){
    tarihDizisi = tarih.split("-");

    gun = tarihDizisi[2];
    ay = tarihDizisi[1];
    yil = tarihDizisi[0];

    yeniTarihDizisi = [ gun, ay, yil];
    return yeniTarihDizisi.join(ayrac);
}


// sadece saat bilgisi kullanarak ve tarihi geçerli günün tarihinden alkarak bir date nesnesi üretir. 
// Eğer tarih da önemliysa, diğer fonksiyonu kullan.
function saatIleZamanNesneOlusturucu ( saat ) {

    if ( ! saatValidate( saat))
        return false;

    simdi = new Date();

    var saatDizi = saat.split(':');

    return (new Date (  simdi.getFullYear(), 
                        simdi.getMonth()+1,
                        simdi.getDate(),
                        saatDizi[0],
                        saatDizi[1] ));



}


function saatFark( s1 , s2 ) {
    s1Obj = saatIleZamanNesneOlusturucu( s1);
    s2Obj = saatIleZamanNesneOlusturucu( s2);

    return Math.floor( (s2Obj - s1Obj) / (1000 * 60));
}

window.saatValidate = function ( saat ) {

    var saatDizi = saat.split(':');

    if (    /^\d+$/.test(saatDizi[0]) 
            && /^\d+$/.test(saatDizi[1]) 
            && saatDizi.length == 2 
            && Number(saatDizi[0]) >= 0 
            && Number(saatDizi[0]) <= 24 
            && Number(saatDizi[1]) >= 0 
            && Number(saatDizi[1]) <= 59 )
        return true;

    return false;
}

//export { saatFark };